/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { defineNuxtRouteMiddleware, navigateTo, useNuxtApp } from '#app'
import Slug from '~/src/Domain/Shared/Identifier/Slug'
import WorkProgramIri from '~/src/Domain/WorkProgram/WorkProgramIri'
import useService from '~/src/UserInterface/App/composables/Container/useService'
import useEngagementState from '~/src/UserInterface/Engagement/composables/useEngagementState'
import useOrganisationState from '~/src/UserInterface/Organisation/composables/useOrganisationState'

/**
 * This middleware must be called on pages with the dynamic '/:workProgramSlug/*' path
 *
 * Prerequisites for this middleware:
 * - The user must be authenticated as a regular user
 * - The organisation state must be populated
 * - The engagements state must be populated
 *
 * This middleware:
 * - Populates work program state if empty
 * - Redirects the user to the Overview page if the work program has already been finished
 */
export default defineNuxtRouteMiddleware(async (to) => {
  const logger = useService('logger')
  logger.info('[WORK_PROGRAM] Middleware fired')

  const nuxtApp = useNuxtApp()
  const { getCurrentEngagement, getCurrentPhase } = useEngagementState()
  const engagement = getCurrentEngagement()
  const workProgramStoreRepository = useService('workProgramStoreRepository')
  const uuidEncoder = useService('uuidEncoder')

  const redirectToOverview = async () => {
    const { getOrganisation } = useOrganisationState()
    const organisation = getOrganisation()
    const organisationId = uuidEncoder.encode(
      organisation.value['@id'].toId(),
    )
    const organisationSlug = organisation.value.getSlug().toString()

    const engagementId = uuidEncoder.encode(engagement.value['@id'].toId())
    const engagementSlug = engagement.value.getSlug().toString()
    const phase = getCurrentPhase()
    const phaseId = uuidEncoder.encode(phase.value['@id'].toId())
    const phaseSlug = phase.value.type.toString()

    return navigateTo(
      // eslint-disable-next-line ts/no-unsafe-argument
      nuxtApp.$localePath({
        name: 'workprogram-Overview',
        params: {
          organisationId,
          organisationSlug,
          engagementId,
          engagementSlug,
          phaseId,
          phaseSlug,
        },
      }),
    )
  }

  if ('workProgramSlug' in to.params && typeof to.params.workProgramSlug === 'string') {
    try {
      workProgramStoreRepository.activateWorkProgram(
        new Slug(to.params.workProgramSlug),
        to.params.workProgramId === undefined || typeof to.params.workProgramId !== 'string'
          ? undefined
          : new WorkProgramIri(`${engagement.value['@id'].toString()}/work_programs/${uuidEncoder.decode(to.params.workProgramId)}`),
      )
    } catch {
      return redirectToOverview()
    }
  }

  const routeName = to.name?.toString().split('__')[0]
  if ('stepSlug' in to.params && typeof to.params.stepSlug === 'string') {
    workProgramStoreRepository.activateWorkProgramStep(new Slug(to.params.stepSlug))
  } else if (routeName === 'workprogram-Finalise') {
    workProgramStoreRepository.activateWorkProgramStep(new Slug('finalize'))
  } else if (routeName === 'workprogram-Settings' || routeName === 'workprogram-Introduction') {
    workProgramStoreRepository.activateWorkProgramStep(
      workProgramStoreRepository.getCurrentWorkProgram().getFirstStep().slug,
    )
  }

  if (workProgramStoreRepository.hasCurrentWorkProgram()) {
    const workProgram = workProgramStoreRepository.getCurrentWorkProgram()
    if (workProgram.finished) {
      return redirectToOverview()
    }
  }
})
